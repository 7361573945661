import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { findYoutubeChannel } from '@/components/UBSafelist/api';
export const useYoutubeChannelData = (selectedChannel)=>{
    return useQuery({
        queryKey: [
            'youtube-channel-data',
            selectedChannel
        ],
        placeholderData: keepPreviousData,
        queryFn: async ()=>{
            if (!selectedChannel) {
                return null;
            }
            const results = await findYoutubeChannel(selectedChannel);
            return {
                ...results,
                channelId: selectedChannel
            };
        }
    });
};
