export const compatibilityLabels = {
    1: 'Premiere Pro (MOGRT)',
    2: 'After Effects',
    3: 'Premiere Pro',
    4: 'DaVinci Resolve',
    5: 'Drag and Drop'
};
export const compatibilityLabelShortnames = {
    1: 'Premiere Pro (MOGRT)',
    2: 'After Effects',
    3: 'Premiere Pro',
    4: 'DaVinci Resolve',
    5: 'Drag & Drop'
};
