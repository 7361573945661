export const DJIGiveawayPopup = {
    subtitle: 'Create more. Win big.'.toUpperCase(),
    mainTitle: 'Join our Instagram giveaway!',
    imgSrc: 'https://musicvine.imgix.net/dji-giveaway.jpg',
    imgAlt: 'DJI giveaway',
    imgSrcCarousel: 'https://musicvine.imgix.net/dji-giveaway-banner-1920x220.png',
    imgAltCarousel: 'DJI giveaway annoucement banner',
    cta: 'Enter now',
    href: 'https://www.instagram.com/p/DGxuwzQKY9x',
    description: 'You could win a DJI Osmo Pocket 3, 1 year subscription to Skillshare, and 1 year of Uppbeat Premium.'
};
