import { getReferralCode, setOwnedReferralCodes, validateReferralCode } from '../utils/helpers';
import { getAuthToken, setAuthToken } from '../auth';
import { updateActiveCampaignEmail, updateActiveCampaignContact, autoRenewACEvent } from '../api/activecampaign/update-contact';
import { trackRegistrationGoogle } from '../utils/analytics';
import { trackReferralRegistration } from '@/api/partnerstack/trackReferralRegistration';
export function loadUserData(userData) {
    const reducerData = {
        isCustomer: userData.hasAccountCustomer,
        isArtist: userData.hasAccountArtist,
        isAdmin: userData.hasAccountAdmin,
        profilePicture: userData.profile_picture,
        email: userData.email,
        emailHash: userData.emailHash,
        isEngaged: userData.is_engaged,
        firstName: userData.firstName,
        fullName: userData.fullName,
        company: userData.company,
        isPremium: userData.isPremium,
        isBusiness: userData.isBusiness,
        isPaymentTabDisplay: userData.isPaymentTabDisplay,
        isSubscriptionTabDisplay: userData.isSubscriptionTabDisplay,
        isEmailVerified: userData.isEmailVerified,
        profit_share_partner: userData.profit_share_partner ? true : false,
        affiliate_partner: userData.affiliate_partner ? true : false,
        remindRemainingDl: userData.remindRemainingDl,
        download_count: userData.download_count,
        remaining_downloads: userData.remaining_downloads,
        totalDownloads: userData.total_downloads,
        renewedDownloadDate: userData.renewed_download_date,
        isGoogleUser: userData.isGoogleUser,
        channelName: userData.channelName,
        currencyLock: userData.currencyLock,
        hasLoaded: true,
        isLoggedIn: userData.is_authenticated,
        creditsCurrent: userData?.credits?.credits_current,
        creditsMax: userData?.credits?.credits_max,
        creditsPerMonth: userData?.credits?.credits_per_month,
        planData: userData.planData,
        aiCredits: userData.aiCredits
    };
    setOwnedReferralCodes(userData.referralCode);
    return {
        type: 'LOAD_USER_DATA',
        userData: reducerData
    };
}
export function updateCreditsCurrent(creditsCurrent) {
    return {
        type: 'UPDATE_CREDITS_CURRENT',
        creditsCurrent
    };
}
export function updateRemainingCredits(creditsCurrent) {
    return {
        type: 'UPDATE_REMAINING_CREDITS',
        creditsCurrent
    };
}
export function updateUserData(userData) {
    return {
        type: 'LOAD_USER_DATA',
        userData: userData
    };
}
export function setRemainingAiUsages(newUsages) {
    return {
        type: 'SET_REMAINING_AI_USAGES',
        newUsages
    };
}
export function fetchAuthenticationState() {
    return fetch(`${process.env.NEXT_PUBLIC_API}auth/authenticationStatus`, {
        method: 'GET',
        mode: 'no-cors'
    }).then((res)=>res.json()).catch((err)=>err);
}
export function resendVerificationEmail(hash) {
    const authToken = getAuthToken();
    let formData = new FormData();
    formData.append('token', authToken);
    return fetch(`${process.env.NEXT_PUBLIC_API}auth/resend-confirm-email`, {
        method: 'POST',
        body: formData
    }).then((res)=>res.json()).catch((err)=>err);
}
/**
 * Multipart Auth Process
 *
 * 1) Check the credentials
 * 2) Submit form to login URL.
 */ export const makeLoginAttempt = (signupData)=>async (dispatch)=>{
        let formData = new FormData();
        formData.append('_username', signupData._username);
        formData.append('_password', signupData._password);
        formData.append('turnstile_token', signupData.turnstileToken);
        formData.append('turnstile_action', signupData.turnstileAction);
        formData.append('create_legacy', true);
        localStorage.setItem('is_google_user', '0');
        localStorage.setItem('quick_login', signupData._username);
        return fetch(`${process.env.NEXT_PUBLIC_API}authentication/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json'
            },
            body: getJsonFromFromData(formData)
        }).then((res)=>res.json());
    };
export function getJsonFromFromData(formData) {
    var object = {};
    formData.forEach((value, key)=>object[key] = value);
    return JSON.stringify(object);
}
export function removeModal() {
    return {
        type: 'REMOVE_MODAL'
    };
}
export function addModal(modal, intent, heading) {
    return {
        type: 'ADD_MODAL',
        modal,
        intent,
        heading
    };
}
export function setPremiumStatus(status) {
    return {
        type: 'SET_PREMIUM_STATUS',
        payload: status
    };
}
export function setLoginStatus(status) {
    return {
        type: 'SET_LOGIN_STATUS',
        isLoggedIn: status
    };
}
export function setEnteredProfession(status) {
    return {
        type: 'SET_HAS_ENTERED_PROFESSION',
        hasEnteredProfession: status
    };
}
export function conditionallyAppendFormData(name, data, formData) {
    if (data) {
        formData.append(name, data);
    }
    return formData;
}
function getThemeId(theme) {
    switch(theme){
        case 'light':
            theme = '0';
            break;
        case 'dark':
            theme = '1';
            break;
    }
    return theme;
}
export const makeRegisterAttempt = (signupData)=>async (dispatch)=>{
        dispatch(showSignupModalLoader(true));
        dispatch(showSignupError(false));
        let chosenTheme = getThemeId(localStorage.getItem('theme'));
        let formData = new FormData();
        formData.append('first_last_name', signupData.firstLastName);
        formData.append('email', signupData.emailAddress);
        formData.append('password', signupData.password);
        formData.append('suppress_welcome', '0'); // Not used in UB
        formData.append('turnstile_token', signupData.turnstileToken);
        formData.append('turnstile_action', signupData.turnstileAction);
        formData.append('rcm', validateReferralCode());
        if (signupData.idempotencyKey) {
            formData.append('idempotency_key', signupData.idempotencyKey);
        }
        formData = conditionallyAppendFormData('partner_code', localStorage.getItem('partner_code'), formData);
        formData = conditionallyAppendFormData('rt_code', localStorage.getItem('rt_code'), formData);
        formData = conditionallyAppendFormData('affiliate_code', localStorage.getItem('as_code'), formData);
        formData = conditionallyAppendFormData('user_referral_code', getReferralCode(), formData);
        formData = conditionallyAppendFormData('share_name_referrer', sessionStorage.getItem('name_consent'), formData);
        formData = conditionallyAppendFormData('ab_seed', localStorage.getItem('ab_seed'), formData);
        formData = conditionallyAppendFormData('ab_timestamp', localStorage.getItem('ab_ts'), formData);
        formData = conditionallyAppendFormData('impact_clickid', localStorage.getItem('irclickid'), formData);
        formData = conditionallyAppendFormData('theme_preference', chosenTheme, formData);
        return fetch(`${process.env.NEXT_PUBLIC_API}user/register`, {
            method: 'POST',
            body: formData
        }).then((res)=>res.json());
    };
export function getLoginRedirect(redirect) {
    let redirectLocation = '';
    if (redirect) {
        switch(redirect.requestIntent){
            case 'my-account':
                redirectLocation = '/my/account';
                break;
            case 'my-plan':
                redirectLocation = '/my/account#your-uppbeat-plan';
                break;
            case 'my-playlists':
                redirectLocation = '/my/playlists';
                break;
            case 'my-downloads':
                redirectLocation = '/my/downloads/tracks';
                break;
            case 'customer-payments':
                redirectLocation = '/my/payments';
                break;
            case 'artist-account':
                redirectLocation = '/artist/account';
                break;
            case 'artist-submissions':
                redirectLocation = '/artist/account/submissions';
                break;
            case 'artist-submit-for-review':
                redirectLocation = '/artist/account/submissions/submit-for-review';
                break;
            case 'artist-submit-approved':
                redirectLocation = '/artist/account/submissions/submit-approved';
                break;
            case 'artist-submit-for-rereview':
                redirectLocation = '/artist/account/submissions/submit-for-rereview';
                break;
            case 'artist-portfolio':
                redirectLocation = '/artist/account/portfolio';
                break;
            case 'artist-statement':
                redirectLocation = '/artist/account/statement';
                break;
            case 'artist-subscription-usage-report':
                if (redirect.requestId) {
                    redirectLocation = `/artist/account/statement/subscription-usage-report/${parseInt(redirect.requestId)}`;
                } else {
                    redirectLocation = '/artist/account/statement';
                }
                break;
            case 'artist-earnings':
                redirectLocation = '/artist/account/artist-earnings';
                break;
            case 'view-invoice':
            case 'view-credit-note':
            case 'license-agreement':
            case 'order-confirmation':
            case 'order-summary':
            case 'download-track':
                if (redirect.requestId) {
                    redirectLocation = `/my/order-summary/${parseInt(redirect.requestId)}`;
                } else {
                    redirectLocation = '/my/license-history';
                }
                break;
            default:
                redirectLocation = null;
        }
    }
    return redirectLocation;
}
export function showSigninSuccess(firstName) {
    return {
        type: 'SHOW_SIGNIN_SUCCESS',
        firstName
    };
}
export function hideSigninSuccess() {
    return {
        type: 'HIDE_SIGNIN_SUCCESS'
    };
}
export function showNotificationSuccess(timerNotification) {
    return {
        type: 'SHOW_NOTIFICATION_SUCCESS',
        payload: timerNotification
    };
}
export function hideNotificationSuccess() {
    return {
        type: 'HIDE_NOTIFICATION_SUCCESS'
    };
}
export function animateInTooltip() {
    return {
        type: 'ANIMATE_IN_SIGNIN_SUCCESS'
    };
}
export function animateOutTooltip() {
    return {
        type: 'ANIMATE_OUT_SIGNIN_SUCCESS'
    };
}
export function showSignupModalLoader(show) {
    return {
        type: 'SIGNUP_SHOW_LOADER',
        showLoader: show
    };
}
export function SignUpSuccess(show) {
    return {
        type: 'SIGNUP_SUCCESS',
        payload: show
    };
}
export function showSignupError(msg) {
    return {
        type: 'SIGNUP_ERROR',
        error: msg
    };
}
export function checkEmail(email) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-check-email-exists?email=${email}&token=${authToken}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function updateEmail(email) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-update-email`, {
            method: 'POST',
            body: JSON.stringify({
                email,
                token: authToken
            }),
            headers: {
                'Content-Type': 'application/json',
                token: authToken || null
            }
        }).then((res)=>{
            updateActiveCampaignEmail();
            return res.json();
        }).catch((err)=>err);
}
export function checkAuthPassword(password) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-customer/check-user-authentication-details`, {
            method: 'POST',
            body: JSON.stringify({
                password_check: password,
                token: authToken
            }),
            headers: {
                'Content-Type': 'application/json',
                token: authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchAccountDetail() {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-customer/account`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchRedeemData() {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}customer/getRedeemData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: authToken
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchReferralFriends() {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}customer/referralFriends`, {
            method: 'POST',
            body: JSON.stringify({
                token: authToken
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function redeemReferralCode() {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}subscription/redeemCode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: authToken
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchCountries() {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}countries`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function updateCustomerAccount(data) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-customer/account`, {
            method: 'POST',
            body: JSON.stringify({
                token: authToken,
                ...data
            }),
            headers: {
                'Content-Type': 'application/json',
                token: authToken || null
            }
        }).then((res)=>{
            updateActiveCampaignContact();
            return res.json();
        }).catch((err)=>err);
}
export function autoRenew(data) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}v2/subscription/account/auto-renew`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                ...data
            }),
            headers: {
                'Content-Type': 'application/json',
                ...data
            }
        }).then((res)=>{
            updateActiveCampaignContact();
            autoRenewACEvent(data?.autoRenew);
            return res.json();
        }).catch((err)=>err);
}
export function getYoutubeChannel(channelID) {
    return ()=>fetch(`https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${channelID}&type=channel&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`, {
            method: 'GET'
        }).then((res)=>res.json()).catch((err)=>err);
}
export function searchYoutubeChannel(searchTerm) {
    return ()=>fetch(`https://www.googleapis.com/youtube/v3/search?part=snippet&q=${searchTerm}&type=channel&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`, {
            method: 'GET'
        }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchOrderSummary(orderID) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-customer/order-summary/${orderID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function googleSignIn(payload) {
    let chosenTheme = getThemeId(localStorage.getItem('theme'));
    if (chosenTheme) {
        payload.theme_preference = chosenTheme;
    }
    return fetch(`${process.env.NEXT_PUBLIC_API}user/google-login`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((res)=>res.json());
}
export function googleSignInActions(response, setUserState) {
    const { isNewUser, email, auth_token, currency } = response;
    setAuthToken(auth_token);
    localStorage.setItem('is_google_user', '1');
    localStorage.removeItem('quick_login');
    if (isNewUser) {
        sessionStorage.setItem('signUpTime', new Date().getTime().toString());
        try {
            trackRegistrationGoogle();
            if (response.is_valid_referral) {
                trackReferralRegistration();
            }
        } catch (e) {
            console.warn('Error tracking event');
        }
    }
}
export function setGoogleLoginSuccess(status) {
    let isNewUser = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false, closeModal = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    return {
        type: 'SET_GOOGLE_LOGIN_SUCCESS',
        status,
        isNewUser,
        closeModal
    };
}
export function setGoogleLoginFailure() {
    let message = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
    return {
        type: 'SET_GOOGLE_LOGIN_FAILURE',
        message
    };
}
export function clearGoogleLoginFlag() {
    return {
        type: 'CLEAR_GOOGLE_LOGIN_FLAG'
    };
}
// RESET PASSWORD API's
export function resetPasswordRequest(payload) {
    let formData = new FormData();
    formData.append('email', payload.email);
    formData.append('turnstile_token', payload.turnstileToken);
    formData.append('turnstile_action', payload.turnstileAction);
    return fetch(`${process.env.NEXT_PUBLIC_API}auth/user-reset-password`, {
        method: 'POST',
        body: formData
    }).then((res)=>{
        if (res.status === 200) {
            return res.json();
        }
    }).catch((err)=>err);
}
export function showEmail() {
    return {
        type: 'SHOW_EMAIL'
    };
}
export function hideEmail() {
    return {
        type: 'HIDE_EMAIL'
    };
}
