export const getTurnstileBypassHeader = ()=>{
    const bypassHeader = process.env.NEXT_PUBLIC_TURNSTILE_BYPASS_HEADER;
    const bypassKey = process.env.NEXT_PUBLIC_TURNSTILE_BYPASS_KEY;
    if (!bypassHeader || !bypassKey) {
        return null;
    }
    const result = {
        [bypassHeader]: bypassKey
    };
    return result;
};
