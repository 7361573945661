import { clsx } from 'clsx';
import { marked } from 'marked';
import { twMerge } from 'tailwind-merge';
import { GenericErrorCode, RecaptchaErrorCode } from '@/types/errors';
import { lowercaseFirstLetter } from '@/utils/lowercaseFirstLetter';
export function cn() {
    for(var _len = arguments.length, inputs = new Array(_len), _key = 0; _key < _len; _key++){
        inputs[_key] = arguments[_key];
    }
    return twMerge(clsx(inputs));
}
export const stripMarkdown = (markdown)=>{
    const html = marked.parse(markdown, {
        async: false
    });
    return html.replace(/<\/?[^>]+(>|$)/g, '').trim();
};
/**
 * Type guard to check if a number is a valid RecaptchaErrorCode
 */ export function isRecaptchaErrorCode(code) {
    return Object.values(RecaptchaErrorCode).includes(code);
}
/**
 * Type guard to check if a number is a valid GenericErrorCode
 */ export function isGenericErrorCode(code) {
    return Object.values(GenericErrorCode).includes(code);
}
/**
 * Type guard to check if a number is a valid BackendErrorCode
 */ export function isBackendErrorCode(code) {
    return isGenericErrorCode(code) || isRecaptchaErrorCode(code);
}
/**
 * Get a user-friendly error message for a recaptcha error code
 * @param code The recaptcha error code received from the backend
 * @returns A user-friendly error message for recaptcha errors
 */ export function getRecaptchaErrorMessage(code) {
    if (!isRecaptchaErrorCode(code)) {
        return 'An unknown error has occurred';
    }
    switch(code){
        case RecaptchaErrorCode.Empty:
            return 'reCAPTCHA response empty';
        case RecaptchaErrorCode.NotSuccessful:
            return 'reCAPTCHA validation not successful';
        case RecaptchaErrorCode.ScoreLow:
            return 'reCAPTCHA v3 score too low';
        case RecaptchaErrorCode.Failed:
            return 'reCAPTCHA validation failed';
        default:
            return 'An unknown error has occurred';
    }
}
export function getGenericErrorMessage(code) {
    if (!isGenericErrorCode(code)) {
        return 'An unknown error has occurred';
    }
    switch(code){
        case GenericErrorCode.OutstandingPayment:
            return 'You currently have an outstanding payment on your subscription';
        case GenericErrorCode.ExpiredFreeSubscription:
            return 'Your free subscription has expired';
        case GenericErrorCode.NoValidSubscription:
            return 'No valid subscription found for your premium account';
        case GenericErrorCode.FairUsageLimitExceeded:
            return 'You have exceeded your fair usage limit';
        case GenericErrorCode.InactiveSubscription:
            return 'Your premium subscription is currently inactive';
        case GenericErrorCode.PausedSubscription:
            return 'Your premium subscription is currently paused';
        case GenericErrorCode.PaymentNotFound:
            return "We couldn't find a payment for your premium subscription";
        case GenericErrorCode.UnverifiedEmail:
            return 'Please verify your email address';
        case GenericErrorCode.TrackNotLive:
            return 'This track is currently not available';
        case GenericErrorCode.PremiumTrackWithFreeAccount:
            return 'This track is only available with a premium subscription';
        case GenericErrorCode.DownloadsExhausted:
            return 'You have used all your available downloads';
        case GenericErrorCode.UnknownError:
        default:
            return 'An unknown error has occurred';
    }
}
/**
 * Get a user-friendly error message for a given error code
 * @param code The error code received from the backend
 * @returns A user-friendly error message
 */ export function getErrorMessage(code) {
    if (isRecaptchaErrorCode(code)) {
        return getRecaptchaErrorMessage(code);
    }
    if (isGenericErrorCode(code)) {
        return getGenericErrorMessage(code);
    }
    return 'An unknown error has occurred';
}
/**
 * Get the first string that is not undefined or an empty string from an
 * infinite number of parameters, as well as has a length greater than 0 when
 * trimmed. This is similar to nullish coalescing but it will also check if
 * the string is empty.
 *
 * @param strings Any number of strings
 * @returns The first string that is not undefined or an empty string
 */ export function getFirstString() {
    for(var _len = arguments.length, strings = new Array(_len), _key = 0; _key < _len; _key++){
        strings[_key] = arguments[_key];
    }
    return strings.find((string)=>string !== undefined && string !== null && string !== '' && string.trim().length > 0) ?? '';
}
/**
 * Get the first string that is not undefined or an empty string from an
 * infinite number of parameters, as well as has a length greater than 0 when
 * trimmed. This is similar to nullish coalescing but it will also check if
 * the string is empty.
 *
 * @param strings Any number of strings
 * @returns The first string that is not undefined or an empty string
 */ export function getFirstStringOrNull() {
    for(var _len = arguments.length, strings = new Array(_len), _key = 0; _key < _len; _key++){
        strings[_key] = arguments[_key];
    }
    return strings.find((string)=>string !== undefined && string !== null && string !== '' && string.trim().length > 0) ?? null;
}
export const getCategoryPageTitle = (param)=>{
    let { primary_heading_visitor, primary_heading, name, userIsAuthenticated } = param;
    const firstString = getFirstString(primary_heading, name);
    if (!userIsAuthenticated) {
        if (primary_heading_visitor) {
            return primary_heading_visitor;
        }
        return 'Free '.concat(lowercaseFirstLetter(firstString));
    }
    return firstString;
};
export const isAssetNew = (setLiveUnixTimestamp)=>{
    if (setLiveUnixTimestamp === 0) {
        return false;
    }
    const sevenDaysAgo = Date.now() - 30 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    const setLiveDate = new Date(setLiveUnixTimestamp * 1000); // Convert Unix timestamp (seconds) to milliseconds
    return setLiveDate.getTime() > sevenDaysAgo;
};
export const getFreeDownloadsText = (credits)=>{
    if (credits === 1) {
        return '1 free download';
    }
    return `${credits} free downloads`;
};
