import { useContext } from 'react';
import { ScrollContext } from '@/providers/ScrollProvider';
import { supportsPassiveEventListeners } from '@/utils/supports-passive-event-listeners';
export const useScrollContainer = ()=>{
    const { scrollContainerRef } = useContext(ScrollContext);
    const scrollTo = (options)=>{
        if (!scrollContainerRef?.current) {
            console.error('The scrollContainerRef is not available, are you calling this from within the ScrollProvider?');
            return;
        }
        scrollContainerRef?.current.scrollTo(options);
    };
    const addScrollEventListener = (callback)=>scrollContainerRef.current?.addEventListener('scroll', callback, supportsPassiveEventListeners() ? {
            passive: true
        } : undefined);
    const removeScrollEventListener = (callback)=>scrollContainerRef.current?.removeEventListener('scroll', callback);
    return {
        container: scrollContainerRef?.current,
        scrollTo,
        addScrollEventListener,
        removeScrollEventListener
    };
};
