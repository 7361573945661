import cleanDeep from 'clean-deep';
import Cookies from 'universal-cookie';
import getHost from './utils/host/get-host';
/**
 * Get a cookie value.
 *
 * On the client this takes the cookie from `document.cookie`, on the server it
 * takes it from the Next request object.
 */ export const getUniversalCookie = function(cookieName) {
    let { req, options } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    if ("object" !== 'undefined') {
        return new Cookies(document.cookie).get(cookieName, options);
    }
    if (!req) {
        return undefined;
    }
    return new Cookies(req.headers?.cookie).get(cookieName, options);
};
/**
 * Set a cookie value.
 */ export const setUniversalCookie = function(cookieName, value) {
    let { options, req } = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    const finalOptions = cleanDeep({
        path: '/',
        domain: getHost(req),
        ...options
    });
    if ("object" !== 'undefined') {
        new Cookies(document.cookie).set(cookieName, value, finalOptions);
        return;
    }
    if (!req) {
        throw new Error('A Next.js request object is required to set a cookie from the server.');
    }
    new Cookies(req.headers?.cookie).set(cookieName, value, finalOptions);
};
/**
 * Remove a cookie value.
 */ export const removeUniversalCookie = function(cookieName) {
    let { req, options } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const finalOptions = cleanDeep({
        path: '/',
        domain: getHost(req),
        ...options
    });
    const finalOptionsApi = cleanDeep({
        path: '/',
        domain: 'api.uppbeat.io',
        ...options
    });
    if ("object" !== 'undefined') {
        new Cookies(document.cookie).remove(cookieName, finalOptions);
        new Cookies(document.cookie).remove(cookieName, finalOptionsApi);
        return;
    }
    if (!req) {
        throw new Error('A Next.js request object is required to remove a cookie from the server.');
    }
    new Cookies(req.headers?.cookie).remove(cookieName, finalOptions);
};
