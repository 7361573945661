import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useContext } from 'react';
import { FileFormatContext } from '@/providers/FileFormatProvider';
import { CACHE_KEY } from './useSettings';
const useFileFormat = ()=>{
    const context = useContext(FileFormatContext);
    if (!context) {
        throw new Error('useFileFormat must be used within a FileFormatProvider');
    }
    const { fileFormat, setFileFormat, defaultFormat } = context;
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (newFormat)=>axios.post('admin/user/updatefileformat', {
                file_format: newFormat
            }, {
                baseURL: process.env.NEXT_PUBLIC_API,
                withCredentials: true
            }),
        onMutate: async (newFormat)=>{
            await queryClient.cancelQueries({
                queryKey: CACHE_KEY
            });
            const previousSettings = queryClient.getQueryData(CACHE_KEY);
            queryClient.setQueryData(CACHE_KEY, (old)=>{
                if (!old) {
                    return old;
                }
                return {
                    ...old,
                    preferences: {
                        ...old.preferences,
                        file_format: newFormat
                    }
                };
            });
            return {
                previousSettings
            };
        },
        onError: (_err, _newFormat, ctx)=>{
            if (ctx?.previousSettings) {
                queryClient.setQueryData(CACHE_KEY, ctx.previousSettings);
            }
        },
        onSettled: async ()=>{
            await queryClient.invalidateQueries({
                queryKey: CACHE_KEY
            });
            setFileFormat(null);
        }
    });
    return {
        fileFormat,
        setFileFormat,
        mutation,
        defaultFormat
    };
};
export default useFileFormat;
