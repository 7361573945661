import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateRemainingCredits } from '@/actions/authenticationAction';
import { updateActiveCampaignContact } from '@/api/activecampaign/update-contact';
import { api } from '@/api/api';
import { AccountModalNames, ModalConversionPoints, useAccountModals } from '@/components/Modals/Account';
import { toast } from '@/components/ui/toast';
import useRemainingCredits from '@/hooks/useRemainingCredits';
import { useSettings } from '@/hooks/useSettings';
import { logEvent } from '@/pages/api/v1/utils';
import { trackMGDownload } from '@/utils/analytics';
import { trackInternalEvent } from '@/utils/tracking';
const getMotionAssetAuthorisation = async (param)=>{
    let { assetId, pageSlug, turnstileToken, turnstileAction, oneTimeToken, idempotencyKey } = param;
    try {
        const { data } = await api.post('authentication/asset/motiongraphic/GetAssetAuthorisation', {
            asset_id: assetId,
            page_slug: pageSlug,
            turnstile_token: turnstileToken,
            turnstile_action: turnstileAction,
            idempotency_key: idempotencyKey,
            one_time_token: oneTimeToken
        });
        if (!data) {
            return null;
        }
        return data;
    } catch (error) {
        if (error instanceof AxiosError) {
            const message = error?.response?.data?.message;
            toast.error('Sorry, download failed unexpectedly', {
                description: message
            });
        }
        console.warn(error);
    }
    return null;
};
const useMGDownload = ()=>{
    const [isDownloading, setIsDownloading] = useState(false);
    const [idempotencyKey, setIdempotencyKey] = useState();
    const { openModalWithConversionPoint } = useAccountModals();
    const { settings } = useSettings();
    const dispatch = useDispatch();
    const { invalidateSettings } = useSettings();
    const { asPath } = useRouter();
    const { remainingCredits } = useRemainingCredits();
    const handleDownload = async (param)=>{
        let { assetId, pageSlug, turnstileToken, turnstileAction } = param;
        const response = {
            mustVerifyAgain: false,
            error: null
        };
        try {
            const assetAuth = await getMotionAssetAuthorisation({
                assetId,
                pageSlug,
                turnstileToken,
                turnstileAction,
                idempotencyKey
            });
            if (!assetAuth) {
                return {
                    ...response,
                    error: 'Failed to obtain asset auth'
                };
            }
            const { asset_url, auth_token, idempotency_key, error } = assetAuth;
            if (idempotency_key) {
                setIdempotencyKey(idempotency_key);
            }
            if (error) {
                return {
                    ...response,
                    error: error.message,
                    mustVerifyAgain: String(error?.code) === 'timeout-or-duplicate'
                };
            }
            if (!auth_token) {
                return {
                    ...response,
                    error: 'Failed to generate auth token'
                };
            }
            window.open(`${asset_url}?auth=${auth_token}`, '_self');
            if (remainingCredits) {
                dispatch(updateRemainingCredits(remainingCredits - 1));
                invalidateSettings();
            }
            setIdempotencyKey(undefined);
            updateActiveCampaignContact();
            void trackInternalEvent({
                type: 'download'
            });
            void logEvent({
                event_type: 'download',
                asPath,
                metadata: {
                    asset_type: 'motion',
                    asset_id: assetId
                }
            });
            trackMGDownload();
            return response;
        } catch (error) {
            toast.error('Sorry, something went wrong');
            return {
                ...response,
                error: typeof error === 'string' ? error : 'Please try again'
            };
        }
    };
    const onDownload = async (assetParams)=>{
        const { isPremium: assetIsPremium, turnstileToken, turnstileAction } = assetParams;
        if (settings?.user.is_authenticated) {
            if (settings?.isPremium || settings?.isBusiness || !assetIsPremium) {
                setIsDownloading(true);
                const { mustVerifyAgain } = await handleDownload({
                    ...assetParams,
                    turnstileToken,
                    turnstileAction
                });
                setIsDownloading(false);
                if (mustVerifyAgain) {
                    return {
                        mustVerifyAgain: true
                    };
                }
                return {};
            }
            if (assetIsPremium) {
                return openModalWithConversionPoint(AccountModalNames.GoPremium, ModalConversionPoints.DownloadPremiumMg);
            }
        }
        if (assetIsPremium) {
            return openModalWithConversionPoint(AccountModalNames.SignUpPremium, ModalConversionPoints.DownloadPremiumMg);
        }
        return openModalWithConversionPoint(AccountModalNames.SignUpFree, ModalConversionPoints.DownloadPremiumMg);
    };
    return {
        onDownload,
        isDownloading
    };
};
export default useMGDownload;
