import { MOTION_GRAPHICS_CATEGORIES, MOTION_GRAPHICS_COLLECTION, MUSIC_CATEGORIES, MUSIC_COLLECTION, MUSIC_COLLECTIONS, MUSIC_CONTRIBUTORS, MUSIC_QUALIFIED_KEYWORDS, MUSIC_TAGS, SFX_CATEGORIES, SFX_COLLECTIONS, SFX_TAGS } from '@/constants/typesense';
import { mergeCollectionHits } from '@/features/SearchBar/utils/mergeCollections';
const normalizeString = (str)=>{
    return str.toLowerCase().replace(/[\s-]+/g, '') // Remove all whitespace and hyphens
    .trim();
};
const getFuzzyMatch = (hits, query)=>{
    const normalizedQuery = normalizeString(query);
    return hits.filter((item)=>{
        if (!item.document.name) {
            return false;
        }
        const normalizedName = normalizeString(item.document.name);
        return normalizedName === normalizedQuery;
    })[0];
};
export const getSuggestedResults = async (query, assetType)=>{
    const params = {
        searches: []
    };
    switch(assetType){
        case 'sfx':
            params.searches = [
                {
                    q: query,
                    collection: SFX_COLLECTIONS,
                    query_by: 'name, keywords',
                    per_page: 12,
                    min_len_1typo: 6,
                    num_typos: 1
                },
                {
                    q: query,
                    collection: SFX_CATEGORIES,
                    query_by: 'name',
                    per_page: 12,
                    min_len_1typo: 6,
                    num_typos: 1
                },
                {
                    q: query,
                    collection: SFX_TAGS,
                    query_by: 'name',
                    per_page: 16,
                    min_len_1typo: 6,
                    num_typos: 1
                }
            ];
            break;
        case 'motion':
            params.searches = [
                {
                    q: query,
                    collection: MOTION_GRAPHICS_COLLECTION,
                    query_by: 'name, keywords',
                    per_page: 12,
                    min_len_1typo: 6,
                    num_typos: 1
                },
                {
                    q: query,
                    collection: MOTION_GRAPHICS_CATEGORIES,
                    query_by: 'name,description',
                    per_page: 12,
                    min_len_1typo: 6,
                    num_typos: 1
                }
            ];
            break;
        default:
            params.searches = [
                {
                    q: query,
                    collection: MUSIC_COLLECTION,
                    query_by: 'name',
                    per_page: 8
                },
                {
                    q: query,
                    collection: MUSIC_CONTRIBUTORS,
                    query_by: 'name',
                    per_page: 6,
                    min_len_1typo: 6,
                    num_typos: 1
                },
                {
                    q: query,
                    collection: MUSIC_COLLECTIONS,
                    query_by: 'name, keywords',
                    per_page: 12,
                    min_len_1typo: 6,
                    num_typos: 1
                },
                {
                    q: query,
                    collection: MUSIC_TAGS,
                    query_by: 'name',
                    per_page: 10,
                    min_len_1typo: 6,
                    num_typos: 1
                },
                {
                    q: query,
                    collection: MUSIC_CATEGORIES,
                    query_by: 'name',
                    per_page: 12,
                    min_len_1typo: 6,
                    num_typos: 1
                },
                {
                    q: query,
                    collection: MUSIC_QUALIFIED_KEYWORDS,
                    query_by: 'name',
                    per_page: 10,
                    min_len_1typo: 6,
                    num_typos: 1
                }
            ];
            break;
    }
    const res = await fetch(`https://${process.env.NEXT_PUBLIC_TYPESENSE_URL}/multi_search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-TYPESENSE-API-KEY': process.env.NEXT_PUBLIC_TYPESENSE_API_KEY
        },
        body: JSON.stringify(params)
    });
    const search = await res.json();
    // Remove empty results.
    const clearedList = search?.results?.filter((result)=>result.hits?.length && result.hits?.length > 0);
    const mergedCollections = mergeCollectionHits([
        ...clearedList
    ]);
    // Define collection priority for when scores are equal.
    // Higher number = higher priority.
    const collectionsValue = {
        'popular music searches': 1,
        'popular sfx searches': 2,
        playlists: 3,
        'sfx collections': 4,
        artists: 5,
        tracks: 6
    };
    const sortedCollections = mergedCollections.sort((a, b)=>{
        const aCollection = a.request_params?.collection_name?.toLowerCase();
        const bCollection = b.request_params?.collection_name?.toLowerCase();
        if (!aCollection || !bCollection) {
            return 0;
        }
        const aCollectionValue = collectionsValue[aCollection];
        const bCollectionValue = collectionsValue[bCollection];
        return aCollectionValue - bCollectionValue;
    });
    return sortedCollections;
};
