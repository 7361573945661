import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { searchYoutubeChannel } from '@/components/UBSafelist/api';
export const useSearchYoutubeChannel = (inputValue)=>{
    return useQuery({
        queryKey: [
            'searchYoutubeChannel',
            inputValue
        ],
        placeholderData: keepPreviousData,
        queryFn: async ()=>{
            if (inputValue.length < 3) {
                return [];
            }
            const results = await searchYoutubeChannel(inputValue);
            return results.items;
        }
    });
};
