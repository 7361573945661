export const MOTION_GRAPHICS_COLLECTION = 'motiongraphics.v2';
export const MOTION_GRAPHICS_CATEGORIES = 'motiongraphicstyles';
export const MOTION_GRAPHICS_CONTRIBUTORS = 'motiongraphics_contributors';
export const SFX_COLLECTION = 'sfx';
export const SFX_CATEGORIES = 'sfxstyles';
export const SFX_TAGS = 'sfxtags';
export const SFX_CONTRIBUTORS = 'artists';
export const SFX_COLLECTIONS = 'sfxcollections';
export const SFX_QUALIFIED_KEYWORDS = 'sfxqualifiedkeywords';
export const MUSIC_COLLECTION = 'tracks';
export const MUSIC_CATEGORIES = 'trackstyles';
export const MUSIC_TAGS = 'tracktags';
export const MUSIC_CONTRIBUTORS = 'artists';
export const MUSIC_COLLECTIONS = 'collections';
export const MUSIC_QUALIFIED_KEYWORDS = 'trackqualifiedkeywords';
export const SEARCH_REDIRECTS = 'searchredirects';
export const PAGE_REDIRECTS = 'pageredirects';
