import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
const createSubscription = async (body)=>axios.post('v2/subscription/create', body, {
        baseURL: process.env.NEXT_PUBLIC_API,
        withCredentials: true,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
        }
    }).catch((err)=>{
        if (err?.response?.data?.error?.message) {
            throw new Error(err.response.data.error.message);
        }
        if (err instanceof Error) {
            throw new Error(err.message);
        }
        throw new Error('An unknown error occurred');
    });
const useCreateSubscription = ()=>{
    const mutation = useMutation({
        mutationKey: [
            'createSubscription'
        ],
        mutationFn: async (body)=>{
            try {
                const response = await createSubscription(body);
                if (response.data.error) {
                    throw new Error(response.data.error.message);
                }
                return response.data;
            } catch (error) {
                return {
                    error: 'Sorry, something went wrong'
                };
            }
        }
    });
    return mutation;
};
export default useCreateSubscription;
