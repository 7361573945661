import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { channelWhitelisted, updateActiveCampaignContact } from '@/api/activecampaign/update-contact';
import { whitelistYouTubeChannel } from '@/components/UBSafelist/api';
import { useSettings } from '@/hooks/useSettings';
import { logEvent } from '@/pages/api/v1/utils';
export const useWhitelistChannel = ()=>{
    const [error, setError] = useState();
    const queryClient = useQueryClient();
    const { settings } = useSettings();
    const { asPath } = useRouter();
    const queryKey = [
        'channelWhitelist',
        settings?.user.id ?? ''
    ];
    const { mutateAsync: whitelistChannel, isPending: isWhitelisting } = useMutation({
        mutationFn: (newChannel)=>whitelistYouTubeChannel(newChannel.channelId, newChannel.title ?? 'undefined'),
        onMutate: async (newChannel)=>{
            setError(undefined);
            // Cancel running queries.
            await queryClient.cancelQueries({
                queryKey
            });
            // Get the previous value, in case of error.
            const previousWhitelist = await queryClient.getQueryData([
                queryKey
            ]);
            if (newChannel.channelId && newChannel.channelTitle) {
                // Optimistically update to the new value.
                queryClient.setQueryData(queryKey, function() {
                    let old = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
                    return [
                        ...old,
                        {
                            channel_id: newChannel.channelId,
                            channel_name: newChannel.channelTitle
                        }
                    ];
                });
            }
            void logEvent({
                event_type: 'safelist_channel',
                asPath,
                metadata: {
                    channel_id: newChannel.channelId.toString()
                }
            });
            void channelWhitelisted(newChannel.title ?? '', newChannel.channelId);
            return {
                previousWhitelist
            };
        },
        onError: ()=>{
            setError('Something went wrong while safelisting your channel.');
        },
        onSettled: async ()=>{
            await queryClient.invalidateQueries({
                queryKey
            });
            updateActiveCampaignContact();
        },
        onSuccess: (ctx)=>{
            if (!ctx.success) {
                setError('Something went wrong while safelisting your channel.');
            }
        }
    });
    return {
        whitelistChannel,
        isWhitelisting,
        error
    };
};
