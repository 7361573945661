import { conditionallyGetCdnApi } from '@/api/cdn';
import { logEvent } from '@/pages/api/v1/utils';
function sanitizePath(path) {
    try {
        const searchParamWhitelist = [
            'type',
            'query'
        ];
        const url = new URL(path, 'https://temp');
        url.searchParams.forEach((_value, key)=>{
            if (!searchParamWhitelist.includes(key)) {
                url.searchParams.delete(key);
            }
        });
        return `${url.pathname}${url.search}`;
    } catch (e) {
        return path;
    }
}
export function logSearch(searchTerm, pageCurrent, pageNext, actionType, assetType) {
    const url = new URL(`${conditionallyGetCdnApi()}suggestion_record`);
    url.searchParams.append('search_term', searchTerm);
    url.searchParams.append('page_curr', sanitizePath(pageCurrent));
    url.searchParams.append('page_next', pageNext);
    url.searchParams.append('action_type', actionType);
    void logEvent({
        event_type: 'search',
        asPath: pageCurrent,
        metadata: {
            type: actionType,
            text: searchTerm,
            next_path: pageNext,
            asset_type: assetType
        }
    });
    return fetch(url).then((res)=>res.json()).catch((err)=>err);
}
