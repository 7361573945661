import * as Sentry from '@sentry/nextjs';
import { v4 as uuidv4 } from 'uuid';
// Cookie configuration with security best practices
const COOKIE_CONFIG = {
    NAME: '7d2b6e12d95',
    DAYS: 400,
    PATH: '/',
    SAME_SITE: 'Lax',
    SECURE: "production" === 'production'
};
// Helper functions
function parseCookieString(cookieStr, name) {
    const match = new RegExp(`(?:^|;\\s*)${name}=([^;]*)`).exec(cookieStr);
    return match ? match[1] : null;
}
function generateUUID() {
    try {
        return window?.crypto?.randomUUID?.() || uuidv4();
    } catch (error) {
        Sentry.captureException(error, {
            tags: {
                function: 'generateUUID'
            }
        });
        return uuidv4();
    }
}
function getCookie(name) {
    try {
        const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
        return match ? match[2] : undefined;
    } catch (error) {
        Sentry.captureException(error, {
            tags: {
                function: 'getCookie'
            }
        });
        return undefined;
    }
}
function setCookie(name, value) {
    try {
        const d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * COOKIE_CONFIG.DAYS);
        const options = [
            `path=${COOKIE_CONFIG.PATH}`,
            `expires=${d.toUTCString()}`,
            COOKIE_CONFIG.SAME_SITE ? `samesite=${COOKIE_CONFIG.SAME_SITE}` : '',
            COOKIE_CONFIG.SECURE ? 'secure' : ''
        ].filter(Boolean).join('; ');
        document.cookie = `${name}=${value}; ${options}`;
        Sentry.addBreadcrumb({
            category: 'device-id',
            message: 'Set device ID cookie on client',
            level: 'info'
        });
    } catch (error) {
        Sentry.captureException(error, {
            tags: {
                function: 'setCookie'
            }
        });
    }
}
// Server-side implementation
function handleServerSide(context) {
    const { req, res } = context;
    const cookies = req.cookies || req.headers?.cookie;
    let cookieValue = null;
    if (cookies) {
        cookieValue = typeof cookies === 'string' ? parseCookieString(cookies, COOKIE_CONFIG.NAME) : cookies[COOKIE_CONFIG.NAME] ?? null;
    }
    Sentry.addBreadcrumb({
        category: 'device-id',
        message: cookieValue ? 'Retrieved device ID from server' : 'No device ID found',
        level: 'info'
    });
    if (!cookieValue && res) {
        cookieValue = uuidv4();
        const expires = new Date();
        expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000 * COOKIE_CONFIG.DAYS);
        const cookieOptions = [
            `Path=${COOKIE_CONFIG.PATH}`,
            `Expires=${expires.toUTCString()}`,
            COOKIE_CONFIG.SAME_SITE ? `SameSite=${COOKIE_CONFIG.SAME_SITE}` : '',
            COOKIE_CONFIG.SECURE ? 'Secure' : ''
        ].filter(Boolean).join('; ');
        res.setHeader('Set-Cookie', `${COOKIE_CONFIG.NAME}=${cookieValue}; ${cookieOptions}`);
        Sentry.addBreadcrumb({
            category: 'device-id',
            message: 'Generated new device ID on server',
            level: 'info',
            data: {
                deviceId: cookieValue
            }
        });
    }
    return cookieValue;
}
// API route implementation
function handleApiRoute(req, res) {
    const cookies = req.cookies || req.headers?.cookie;
    let cookieValue = null;
    if (cookies) {
        cookieValue = typeof cookies === 'string' ? parseCookieString(cookies, COOKIE_CONFIG.NAME) : cookies[COOKIE_CONFIG.NAME] ?? null;
    }
    Sentry.addBreadcrumb({
        category: 'device-id',
        message: cookieValue ? 'Retrieved device ID from API route' : 'No device ID found in API route',
        level: 'info'
    });
    if (!cookieValue && res) {
        cookieValue = uuidv4();
        const expires = new Date();
        expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000 * COOKIE_CONFIG.DAYS);
        const cookieOptions = [
            `Path=${COOKIE_CONFIG.PATH}`,
            `Expires=${expires.toUTCString()}`,
            COOKIE_CONFIG.SAME_SITE ? `SameSite=${COOKIE_CONFIG.SAME_SITE}` : '',
            COOKIE_CONFIG.SECURE ? 'Secure' : ''
        ].filter(Boolean).join('; ');
        // Set the cookie in the response headers
        res.setHeader('Set-Cookie', `${COOKIE_CONFIG.NAME}=${cookieValue}; ${cookieOptions}`);
        Sentry.addBreadcrumb({
            category: 'device-id',
            message: 'Generated new device ID in API route',
            level: 'info',
            data: {
                deviceId: cookieValue
            }
        });
    }
    return cookieValue;
}
// Client-side implementation
function handleClientSide() {
    const existingId = getCookie(COOKIE_CONFIG.NAME);
    if (existingId) {
        Sentry.addBreadcrumb({
            category: 'device-id',
            message: 'Retrieved existing device ID from client',
            level: 'info'
        });
        return existingId;
    }
    const uuid = generateUUID();
    setCookie(COOKIE_CONFIG.NAME, uuid);
    Sentry.addBreadcrumb({
        category: 'device-id',
        message: 'Generated new device ID on client',
        level: 'info',
        data: {
            deviceId: uuid
        }
    });
    return uuid;
}
/**
 * Gets or generates a unique device ID for tracking and analytics purposes.
 * Works in client-side, server-side, and API route environments.
 *
 * @param contextOrReq - GetServerSideProps context or NextApiRequest (for server-side execution)
 * @param res - NextApiResponse (only needed when using in API routes)
 * @returns The device ID string or null if unable to retrieve/generate
 */ export const getDeviceId = function() {
    let contextOrReq = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : null, res = arguments.length > 1 ? arguments[1] : void 0;
    try {
        // Server-side execution in getServerSideProps
        if (contextOrReq && 'req' in contextOrReq && "object" === 'undefined') {
            return handleServerSide(contextOrReq);
        }
        // API route execution
        if (contextOrReq && !('req' in contextOrReq) && "object" === 'undefined' && res) {
            return handleApiRoute(contextOrReq, res);
        }
        // Client-side execution
        if (typeof document !== 'undefined' && "object" !== 'undefined') {
            return handleClientSide();
        }
        return null;
    } catch (error) {
        Sentry.captureException(error, {
            tags: {
                function: 'getDeviceId'
            }
        });
        try {
            return uuidv4();
        } catch  {
            return null;
        }
    }
};
