import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'sonner';
import { api } from '@/api/api';
const CURRENT_VERSION = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;
async function fetchLatestVersion() {
    const { data } = await api.get('page/latest_version', {
        headers: {
            'X-Skip-CDN': 'true'
        }
    });
    return data.sha;
}
export function useVersionCheck() {
    const [hasShownToast, setHasShownToast] = useState(undefined);
    const { data: latestVersion, isError, error } = useQuery({
        queryKey: [
            'version-check'
        ],
        queryFn: fetchLatestVersion
    });
    if (isError) {
        Sentry.captureException(error, {
            tags: {
                component: 'useVersionCheck',
                currentVersion: CURRENT_VERSION
            }
        });
    }
    if (latestVersion && CURRENT_VERSION && latestVersion !== CURRENT_VERSION) {
        if (!hasShownToast || hasShownToast !== latestVersion) {
            toast.message('New version available', {
                description: 'Please refresh the page to get the latest updates.',
                action: {
                    label: 'Refresh',
                    onClick: ()=>window.location.reload()
                },
                duration: Infinity
            });
            setHasShownToast(latestVersion);
        }
    }
}
