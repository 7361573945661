import { useCallback } from 'react';
export const useTranslate = ()=>{
    // If ns is not provided, we try use the provider namespace.
    const internalT = useCallback((key, defaultValue)=>{
        if (!key) {
            return '';
        }
        if (!defaultValue) {
            return key;
        }
        return defaultValue;
    }, []);
    return {
        t: internalT,
        isLoading: false
    };
};
