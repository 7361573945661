const formatCurrency = (value, locale, currency)=>{
    // Convert the value from pence/cents to the main unit (e.g., from pence to pounds)
    const convertedValue = value / 100;
    // Use Intl.NumberFormat to format the value as currency
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency
    });
    return formatter.format(convertedValue);
};
export default formatCurrency;
