import { MOTION_GRAPHICS_COLLECTION, MUSIC_COLLECTION, SFX_COLLECTION } from '@/constants/typesense';
export const mapAssetTypeToCollectionName = (assetType)=>{
    if (assetType === 'motion') {
        return MOTION_GRAPHICS_COLLECTION;
    }
    if (assetType === 'music') {
        return MUSIC_COLLECTION;
    }
    if (assetType === 'sfx') {
        return SFX_COLLECTION;
    }
    return assetType;
};
