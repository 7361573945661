import { useEffect, useState } from 'react';
export function useIsClient() {
    const [isClient, setClient] = useState(false);
    useEffect(()=>{
        if ("object" !== 'undefined') {
            // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
            setClient(true);
        }
    }, []);
    return isClient;
}
