import { getTurnstileBypassHeader } from '@/lib/turnstile';
function getJsonFromFromData(formData) {
    const object = {};
    formData.forEach((value, key)=>{
        object[key] = value;
    });
    return JSON.stringify(object);
}
const onMakeLoginAttempt = async (param)=>{
    let { _username, _password, turnstileToken, turnstileAction, idempotencyKey } = param;
    const formData = new FormData();
    const turnstileBypassHeader = getTurnstileBypassHeader();
    formData.append('_username', _username);
    formData.append('_password', _password);
    formData.append('turnstile_token', turnstileToken);
    formData.append('turnstile_action', turnstileAction);
    formData.append('create_legacy', 'true');
    if (idempotencyKey) {
        formData.append('idempotency_key', idempotencyKey);
    }
    localStorage.setItem('is_google_user', '0');
    localStorage.setItem('quick_login', _username);
    return fetch(`${process.env.NEXT_PUBLIC_API}authentication/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            ...turnstileBypassHeader
        },
        body: getJsonFromFromData(formData)
    }).then((res)=>res.json());
};
export default onMakeLoginAttempt;
