import { Compatibility } from '@/components/TypesenseFilters/types';
export const getCompatibilityVersionText = (compatibility)=>{
    const map = {
        [Compatibility.MOGRT]: 'Adobe CC 2022+',
        [Compatibility.PremierePro]: 'Adobe CC 2022+',
        [Compatibility.AfterEffects]: 'Adobe CC 2022+',
        [Compatibility.DragAndDrop]: 'For all major editing software',
        [Compatibility.DaVinciResolve]: 'Resolve 18'
    };
    if (compatibility in map) {
        return map[compatibility];
    }
    return null;
};
