import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { removeWhitelistedYouTubeChannel } from '@/components/UBSafelist/api';
import { useSettings } from '@/hooks/useSettings';
import { logEvent } from '@/pages/api/v1/utils';
export const useRemoveChannel = ()=>{
    const [error, setError] = useState(undefined);
    const queryClient = useQueryClient();
    const { asPath } = useRouter();
    const { settings } = useSettings();
    const queryKey = [
        'channelWhitelist',
        settings?.user.id
    ];
    const { mutateAsync: removeChannel, isPending: isRemoving } = useMutation({
        mutationFn: (channelId)=>removeWhitelistedYouTubeChannel(channelId),
        onMutate: async (channelId)=>{
            setError(undefined);
            void logEvent({
                event_type: 'remove_safelist_channel',
                asPath,
                metadata: {
                    channel_id: channelId.toString()
                }
            });
            // Cancel running queries.
            await queryClient.cancelQueries({
                queryKey
            });
            // Get the previous value, in case of error.
            const previousWhitelist = queryClient.getQueryData(queryKey);
            // Optimistically update to the new value.
            queryClient.setQueryData(queryKey, (old)=>{
                if (!old) {
                    return undefined;
                }
                return old.filter((item)=>!item || item.id !== channelId);
            });
            return {
                previousWhitelist
            };
        },
        // If the mutation fails, use the context returned from onMutate to roll back.
        onError: (_err, _newTodo, context)=>{
            queryClient.setQueryData(queryKey, context?.previousWhitelist);
            setError('Something went wrong while removing the channel.');
        },
        // Invalidate and refetch on success or error.
        onSettled: ()=>queryClient.invalidateQueries({
                queryKey
            }),
        onSuccess: (ctx)=>{
            if (!ctx?.success) {
                setError('Something went wrong while removing the channel.');
            } else {
                setError(undefined);
            }
        }
    });
    return {
        removeChannel,
        isRemoving,
        error
    };
};
